function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

import { merge } from 'rxjs';

/**
  Merges all epics into a single one.
 */
export var combineEpics = function combineEpics() {
  for (var _len = arguments.length, epics = Array(_len), _key = 0; _key < _len; _key++) {
    epics[_key] = arguments[_key];
  }

  var merger = function merger() {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return merge.apply(undefined, _toConsumableArray(epics.map(function (epic) {
      var output$ = epic.apply(undefined, args);
      if (!output$) {
        throw new TypeError('combineEpics: one of the provided Epics "' + (epic.name || '<anonymous>') + '" does not return a stream. Double check you\'re not missing a return statement!');
      }
      return output$;
    })));
  };

  // Technically the `name` property on Function's are supposed to be read-only.
  // While some JS runtimes allow it anyway (so this is useful in debugging)
  // some actually throw an exception when you attempt to do so.
  try {
    Object.defineProperty(merger, 'name', {
      value: 'combineEpics(' + epics.map(function (epic) {
        return epic.name || '<anonymous>';
      }).join(', ') + ')'
    });
  } catch (e) {}

  return merger;
};