import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAb4iC7S3d4ijgtxvZRcuz41m2aK1w--V8",
  authDomain: "income-expenses-f84a7.firebaseapp.com",
  projectId: "income-expenses-f84a7",
  storageBucket: "income-expenses-f84a7.appspot.com",
  messagingSenderId: "173193217697",
  appId: "1:173193217697:web:927eb3e8cb57ec395152e0",
};

export default initializeApp(firebaseConfig);
